import React, { Component } from "react";

class Intro extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="trainers-intro">
					<h1>Our Trainers</h1>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio qui
						earum aperiam, corporis beatae perspiciatis quis ex fuga delectus culpa aut
						sunt dicta doloremque doloribus illum sequi optio asperiores Lorem, ipsum
						dolor sit amet consectetur adipisicing elit. Dolores repellat architecto
						repudiandae quidem libero numquam id voluptates asperiores eum, non, ab
						ratione consequatur debitis illo? Doloribus deleniti consectetur atque
						laudantium ratione quos suscipit corrupti blanditiis. Lorem ipsum dolor sit
						amet consectetur adipisicing elit. Esse a nobis porro omnis pariatur ratione
						ullam placeat unde fugit vitae.
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default Intro;
