import React, { Component } from "react";

class Intro extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<div className="contact-intro">
					<h1>Contact Information</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis
						recusandae quod earum dolores nulla enim!
					</p>
				</div>
			</React.Fragment>
		);
	}
}

export default Intro;
